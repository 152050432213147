var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建商品灭失","width":880,"visible":_vm.visible,"confirmLoading":_vm.loading,"maskClosable":false},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"货品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'barcode',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'barcode',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"allow-clear":"","showSearch":"","show-arrow":true,"not-found-content":_vm.fetching ? undefined : null,"default-active-first-option":false,"filter-option":false,"placeholder":"请输入货品名称"},on:{"search":this.goodsSearch,"change":_vm.handleGoodsChange}},[(_vm.fetching)?_c('a-spin',{attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.dataSource_wms_goods),function(d){return _c('a-select-option',{key:d.barcode,attrs:{"value":d.barcode}},[_vm._v(" "+_vm._s(d.name)+"（"+_vm._s(d.barcode)+"） ")])})],2)],1),_c('a-form-item',{attrs:{"label":"仓库"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'warehouse_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'warehouse_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"仓库名称"},on:{"search":_vm.handleWmsWarehouseSearch,"change":_vm.handleWmsWarehouseChange}},_vm._l((_vm.dataSource_wms_warehouse),function(d){return _c('a-select-option',{key:d.warehouse_id,attrs:{"value":d.warehouse_id}},[_vm._v(" "+_vm._s(d.warehouse)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"库位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'cargo_space_id',
                {
                  rules: [
                    { required: true, message: '' },
                  ],
                },
              ]),expression:"[\n                'cargo_space_id',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                  ],\n                },\n              ]"}],attrs:{"option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"库位名称"},on:{"change":_vm.handleWmsCargoSpaceChange}},_vm._l((_vm.dataSource_wms_cargo_space_filter),function(d){return _c('a-select-option',{key:d.cargo_space_id,attrs:{"value":d.cargo_space_id}},[_vm._v(" "+_vm._s(d.cargo_space)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}]}),(true)?_c('span',{staticStyle:{"padding-left":"10px"}},[_vm._v("最大值："+_vm._s(_vm.transfer_max_count))]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"原因"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reason', {rules: [{required: true, message: '请输入'}]}]),expression:"['reason', {rules: [{required: true, message: '请输入'}]}]"}],attrs:{"auto-size":{ minRows: 3, maxRows: 5 }}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }