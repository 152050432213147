<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="仓库">
              <a-select
                show-search
                allow-clear
                v-model="queryParam.warehouse_id"
                :not-found-content="fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                placeholder="仓库名称"
                @search="this.warehouseSearch"
                @change="handleWarehouseChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="库位">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                placeholder="库位名称"
                show-search
                allow-clear
                @search="handleCargoSpaceSearch"
                v-model="queryParam.cargo_space_id"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/goods_loss/modules/CreateForm'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { cargo_space_list } from '@/api/c_wms_cargo_space'

import { goods_loss_list, goods_loss_create } from '@/api/c_wms_goods_loss'

import debounce from 'lodash/debounce'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    this.warehouseSearch = debounce(this.handleWarehouseSearch, 800)
    return {
      loading: false,
      fetching: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      mdl: {},
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      // 查询参数
      queryParam: {
        warehouse_id: undefined,
        cargo_space_id: undefined
      },
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          ellipsis: true,
          width: 150,
          dataIndex: 'warehouse'
        },
        {
          title: '库位',
          ellipsis: true,
          width: 150,
          dataIndex: 'cargo_space'
        },
        {
          title: '货品条形码',
          ellipsis: true,
          width: 100,
          dataIndex: 'goods_purchase'
        },
        {
          width: 100,
          title: '数量',
          dataIndex: 'count',
          ellipsis: true
        },
        {
          width: 100,
          title: '创建人',
          dataIndex: 'create_by',
          ellipsis: true
        },
        {
          width: 100,
          title: '原因',
          dataIndex: 'reason',
          ellipsis: true
        },
        {
          width: 100,
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_loss_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.handleWarehouseSearch(undefined)
  },
  methods: {
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          goods_loss_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleWarehouseSearch (value) {
      this.dataSource_wms_warehouse = []
      this.fetching = true
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleWarehouseChange (value) {
      this.queryParam.warehouse_id = value
      if (value === undefined) {
        this.handleWarehouseSearch(undefined)
      }
      this.handleCargoSpaceSearch(undefined)
    },
    handleCargoSpaceSearch (value) {
      this.dataSource_wms_cargo_space = []
      this.queryParam.cargo_space_id = undefined
      if (!this.queryParam.warehouse_id) {
        return
      }
      cargo_space_list({ warehouse_id: this.queryParam.warehouse_id, name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_cargo_space = result
      })
    }
  }
}
</script>
