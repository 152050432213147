import request from '@/utils/request'
const requestApi = {
  wms_goods_loss_list: '/wms/warehouse/goods_loss/',
  wms_goods_loss_create: '/wms/warehouse/goods_loss/'
}

export function goods_loss_list (query) {
  return request({
    url: requestApi.wms_goods_loss_list,
    method: 'get',
    params: query
  })
}

export function goods_loss_create (data) {
  return request({
    url: requestApi.wms_goods_loss_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
