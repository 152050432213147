<template>
  <a-modal
    title="新建商品灭失"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="货品">
              <a-select
                allow-clear
                showSearch
                :show-arrow="true"
                :not-found-content="fetching ? undefined : null"
                :default-active-first-option="false"
                :filter-option="false"
                v-decorator="[
                  'barcode',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入货品名称"
                @search="this.goodsSearch"
                @change="handleGoodsChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in dataSource_wms_goods" :key="d.barcode" :value="d.barcode">
                  {{ d.name }}（{{ d.barcode }}）
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="仓库">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'warehouse_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="仓库名称"
                @search="handleWmsWarehouseSearch"
                @change="handleWmsWarehouseChange"
              >
                <a-select-option v-for="d in dataSource_wms_warehouse" :key="d.warehouse_id" :value="d.warehouse_id">
                  {{ d.warehouse }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="库位">
              <a-select
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'cargo_space_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="库位名称"
                @change="handleWmsCargoSpaceChange"
              >
                <a-select-option v-for="d in dataSource_wms_cargo_space_filter" :key="d.cargo_space_id" :value="d.cargo_space_id">
                  {{ d.cargo_space }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数量">
              <a-input-number v-decorator="['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
              <span style="padding-left: 10px" v-if="true">最大值：{{ transfer_max_count }}</span>
            </a-form-item>
            <a-form-item label="原因">
              <a-textarea v-decorator="['reason', {rules: [{required: true, message: '请输入'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { warehouse_list } from '@/api/c_wms_warehouse'
import { wms_warehouse_inventory_list } from '@/api/c_wms_period_inventory'
import { goods_list } from '@/api/c_wms_goods'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['warehouse_id', 'cargo_space_id', 'goods_purchase_id', 'count', 'reason']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.goodsSearch = debounce(this.handleGoodsSearch, 800)
    return {
      fetching: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dataSource_wms_goods_inventory: [],
      dataSource_wms_warehouse: [],
      dataSource_wms_cargo_space: [],
      dataSource_wms_cargo_space_filter: [],
      dataSource_wms_goods: [],
      transfer_max_count: 0,
      select_warehouse: -1,
      select_cargo_space: -1,
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 }
        }
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleGoodsSearch(undefined)
  },
  methods: {
    handleDataChange (value) {
      console.log(value)
    },
    filterOption (input, option) {
      console.log(input, option.componentOptions.children[0].text, this.dataSource_wms_warehouse.length + '-------------------------')
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    handleWmsWarehouseSearch (value) {
      warehouse_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_warehouse = result
        console.log('---' + result.length, this.dataSource_wms_warehouse.length)
      })
    },
    handleWmsWarehouseChange (value) {
      const objneo = this.dataSource_wms_warehouse.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_warehouse)
      var obj = Object.assign({}, objneo)
      console.log(obj, value)
      this.select_warehouse = value
      this.form.setFieldsValue({ cargo_space_id: undefined })
      this.handleWmsCargoSpaceSearch(value)
    },
    handleWmsCargoSpaceSearch (value) {
      this.dataSource_wms_cargo_space_filter = this.dataSource_wms_cargo_space.filter(item => {
        return item.warehouse_id === value
      })
    },
    handleWmsCargoSpaceChange (value) {
      console.log(value)
      const objneo = this.dataSource_wms_cargo_space.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_wms_cargo_space)
      var obj = Object.assign({}, objneo)
      console.log(obj)
      this.select_cargo_space = value

      if (this.select_warehouse !== -1 && this.select_cargo_space !== -1) {
        this.transfer_max_count = 0
        this.dataSource_wms_goods_inventory.filter(item => {
          return item.cargo_space_id === this.select_cargo_space && item.warehouse_id === this.select_warehouse
        }).forEach(item => {
          this.transfer_max_count += Number(item.count)
        })
      }
    },
    handleGoodsSearch (name) {
      this.dataSource_wms_goods = []
      this.fetching = true
      goods_list({ name: name }).then((res) => {
        const result = res.data.entries || []
        this.dataSource_wms_goods = result
      }).finally(() => {
        this.fetching = false
      })
    },
    handleGoodsChange (value) {
      this.form.setFieldsValue({
        warehouse_id: undefined,
        cargo_space_id: undefined
      })
      this.dataSource_wms_warehouse = []
      this.dataSource_wms_cargo_space = []
      this.dataSource_wms_cargo_space_filter = []
      if (value === undefined) {
        this.handleGoodsSearch(undefined)
        return
      }
      wms_warehouse_inventory_list({ barcode: value }).then(res => {
        console.log(res)
        const result = res.data.entries || []
        if (result) {
          this.dataSource_wms_goods_inventory = result
          const local_warehouse = []
          const local_cargo_space = []
          result.forEach(item => {
            const obj_warehouse = local_warehouse.find((warehouse) => {
              return warehouse.warehouse_id === item.warehouse_id
            })
           if (obj_warehouse === undefined) {
             local_warehouse.push(item)
           }

            const obj_cargo_space = local_cargo_space.find((cargo_space) => {
              return cargo_space.cargo_space_id === item.cargo_space_id
            })
            if (obj_cargo_space === undefined) {
              local_cargo_space.push(item)
            }
          })
          this.dataSource_wms_warehouse = local_warehouse
          this.dataSource_wms_cargo_space = local_cargo_space
        }
      })
    }
  }
}
</script>
